import React, { useState, useEffect, useRef } from "react"
import qs from "qs"

import { graphql, Link, navigate } from "gatsby"
import GatsbyImage from "gatsby-image/withIEPolyfill"
import {
  InstantSearch,
  Configure,
  connectHits,
  connectSearchBox,
  connectPagination,
  connectMenu,
  connectHierarchicalMenu,
} from "react-instantsearch-dom"
import moment from "moment"
import algoliasearch from "algoliasearch/lite"
import { Box, Button, Flex } from "@singita/components"
import { orderBy } from "lodash"
import { SectionHeading, Meta } from "@singita/components"
import { generateBlogSlug } from "../common/utils"
import {
  Container,
  Heading,
  GridContainer,
  Pagination,
  Input,
  Filter,
  FilterItem,
  Card,
} from "@singita/components"
import { mapperWithFunction, createSlug } from "../common/utils"
import GridItem from "../components/GridItem"
import { Media } from "gatsby-singita-theme/src/Media"
import LinkWrapper from "../components/Link"
import useAllSitePages_PageExists from "../hooks/useAllSitePagesQuery"
import { getFluidGatsbyImage } from "gatsby-singita-theme/src/common/images"
import { useLocation } from "@reach/router"

const createURL = state => {
  return `?${qs.stringify(state)}`
}

const searchStateToUrl = searchState =>
  searchState ? createURL(searchState) : ""

const urlToSearchState = location => {
  qs.parse(location.search.slice(1))
  return qs.parse(location.search.slice(1))
}

const DEBOUNCE_TIME = 400

export const ARCHIVE_TYPES = {
  BLOG: "Blog Post",
  PRESS: "Press Release",
  PRESS_FEATURES: "Press Feature",
  WILDLIFE: "Wildlife Story",
  AWARDS: "Awards",
  CAREER: "Careers",
  AUTHOR: "Author",
}

const ARTICLE_TYPES = {
  BLOG: "Blog Post",
  PRESS: "Press Release",
  WILDLIFE: "Wildlife Report",
  PRESS_FEATURES: "Press Feature",
  BUSH_STORY: "Bush Stories",
}

const ARCHIVE_INDEX_MAP = {
  [ARCHIVE_TYPES.BLOG]: "articles_date_desc",
  [ARCHIVE_TYPES.PRESS_FEATURES]: "articles_date_desc",
  [ARCHIVE_TYPES.PRESS]: "articles_date_desc",
  [ARCHIVE_TYPES.WILDLIFE]: "articles_date_desc",
  [ARCHIVE_TYPES.AUTHOR]: "articles_date_desc",
  [ARCHIVE_TYPES.AWARDS]: "awards",
  [ARCHIVE_TYPES.CAREER]: "career",
}

// Deprecate when everything has moved to the new indices
const OLD_ARCHIVE_POST_TYPE_MAP = {
  [ARCHIVE_TYPES.WILDLIFE]: "Wildlife Report",
  [ARCHIVE_TYPES.PRESS]: "Press Release",
  [ARCHIVE_TYPES.PRESS_FEATURES]: "Press Feature",
}

const NEW_ARCHIVE_POST_TYPE_MAP = {
  [ARCHIVE_TYPES.BLOG]: ARTICLE_TYPES.BLOG,
  [ARCHIVE_TYPES.WILDLIFE]: ARTICLE_TYPES.WILDLIFE,
  [ARCHIVE_TYPES.PRESS_FEATURES]: ARTICLE_TYPES.PRESS_FEATURES,
}

const ARCHIVE_FILTER_ATTRIBUTE_MAP = {
  [ARCHIVE_TYPES.BLOG]: ["articleType", "category"],
  [ARCHIVE_TYPES.PRESS]: ["year"],
  [ARCHIVE_TYPES.PRESS_FEATURES]: ["year"],
  [ARCHIVE_TYPES.WILDLIFE]: ["articleType", "region", "year_hier.lvl0"],
  [ARCHIVE_TYPES.AWARDS]: ["regions", "year"],
  [ARCHIVE_TYPES.CAREER]: ["regions"],
  [ARCHIVE_TYPES.AUTHOR]: ["author"],
}

const REFINEMENT_NAME_MAP = {}

const ATTRIBUTE_NAMES = {
  regions: "region",
  region: "region",
  ["year_hier.lvl0"]: "month",
  year: "date",
  articleType: "type",
  category: "category",
}

const getArchiveImage = (image, headline, description) => {
  if (image.includes("https://singita-md.s3.amazonaws.com/")) {
    const key = {
      bucket: "singita-md",
      key: image.replace("https://singita-md.s3.amazonaws.com/", ""),
      edits: {
        resize: {
          width: 735,
          height: 365,
        },
      },
    }
    const src = `https://d2o51t207iusce.cloudfront.net/${btoa(
      JSON.stringify(key)
    )}`

    return (
      <img
        alt={description || headline || null}
        src={src}
        style={{ height: "100%", width: "100%", objectFit: "cover" }}
      />
    )
  }
  const fluid = getFluidGatsbyImage(
    { source: image, width: 735, height: 365 },
    { maxWidth: 735, maxHeight: 365, quality: 90, isContentful: true }
  )
  return (
    <GatsbyImage
      alt={description || headline || null}
      fluid={fluid}
      objectFit="cover"
      style={{ height: "100%", width: "100%" }}
    />
  )
}

const CARD_PROPS_MAP = {
  [ARCHIVE_TYPES.BLOG]: {
    variant: () => "standard",
    headline: ["headline"],
    author: ["author"],
    image: ({ archive, image, description, headline }) => {
      if (archive) {
        return getArchiveImage(image, headline, description)
      }

      const fluid = getFluidGatsbyImage(
        { source: image, width: 800, height: 600 },
        { maxWidth: 600, quality: 90, isContentful: true }
      )
      return (
        <GatsbyImage
          alt={description || headline || null}
          fluid={fluid}
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
        />
      )
    },
    imageSx: ({ isFilterLeft }) => ({
      height: [
        "195px",
        "265px",
        "265px",
        "265px",
        isFilterLeft ? "195px" : "265px",
      ],
      width: "100%",
      objectFit: "cover",
    }),
    meta: ({ categories, date }) => {
      const dateObj = new moment(date)
      return [
        { text: `${dateObj.format("MMMM DD, YYYY")}`, color: "textDark" },
        ...(categories
          ? categories.map(category => ({
              text: category,
              color: "brandBrown",
            }))
          : []),
      ]
    },
    isLink: () => true,
    slug: ({ date, slug, permalink, archive = false }) => {
      if (archive && permalink) {
        return permalink
      }

      const dateObj = new moment(date)
      return generateBlogSlug(
        permalink,
        dateObj.format("YYYY"),
        dateObj.format("MM")
      )
    },
  },
  [ARCHIVE_TYPES.PRESS]: {
    variant: () => "standard",
    headline: ["headline"],
    author: ["author"],
    image: ({ archive, image, description, headline }) => {
      if (archive) {
        return getArchiveImage(image, headline, description)
      }
      const fluid = getFluidGatsbyImage(
        { source: image, width: 800, height: 600 },
        { maxWidth: 600, quality: 90, isContentful: true }
      )
      return (
        <GatsbyImage
          alt={description || headline || null}
          fluid={fluid}
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
        />
      )
    },
    imageSx: ({ isFilterLeft }) => ({
      height: [
        "195px",
        "265px",
        "265px",
        "265px",
        isFilterLeft ? "195px" : "265px",
      ],
      width: "100%",
      objectFit: "cover",
    }),
    meta: ({ categories, date }) => {
      const dateObj = new moment(date)
      return [
        { text: `${dateObj.format("MMMM DD, YYYY")}`, color: "textDark" },
        ...(categories
          ? categories.map(category => ({
              text: category,
              color: "brandBrown",
            }))
          : []),
      ]
    },
    isLink: () => true,
    slug: ({ date, slug, permalink, archive = false }) => {
      if (archive && permalink) {
        return permalink
      }

      const dateObj = new moment(date)
      return generateBlogSlug(
        permalink,
        dateObj.format("YYYY"),
        dateObj.format("MM")
      )
    },
  },
  [ARCHIVE_TYPES.PRESS_FEATURES]: {
    variant: () => "text",
    headline: ["headline"],
    author: ["author"],
    imageSx: ({ isFilterLeft }) => ({
      height: [
        "195px",
        "265px",
        "265px",
        "265px",
        isFilterLeft ? "195px" : "265px",
      ],
      width: "100%",
      objectFit: "cover",
    }),
    meta: ({ categories, date }) => {
      const dateObj = new moment(date)
      return [
        { text: `${dateObj.format("MMMM YYYY")}`, color: "textDark" },
        ...(categories
          ? categories.map(category => ({
              text: category,
              color: "brandBrown",
            }))
          : []),
      ]
    },
    isLink: () => false,
    children: value => {
      if (value.pdf) {
        return (
          <Button
            variant="text"
            as={"a"}
            href={value.pdf}
            icon="download"
            iconPosition="right"
            colorScheme="brandBrown"
          >
            Download media
          </Button>
        )
      }
    },
  },
  [ARCHIVE_TYPES.WILDLIFE]: {
    variant: () => "standard",
    headline: ["headline"],
    author: ["author"],
    image: ({ archive, image, description, headline }) => {
      if (archive) {
        return getArchiveImage(image, headline, description)
      }
      const fluid = getFluidGatsbyImage(
        { source: image, width: 800, height: 600 },
        { maxWidth: 600, quality: 90, isContentful: true }
      )
      return (
        <GatsbyImage
          alt={description || headline || null}
          fluid={fluid}
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
        />
      )
    },
    imageSx: ({ isFilterLeft }) => ({
      height: ["195px", "265px", "265px", isFilterLeft ? "195px" : "265px"],
      width: "100%",
      objectFit: "cover",
    }),
    meta: ({ categories, date }) => {
      const dateObj = new moment(date)
      return [
        { text: `${dateObj.format("MMMM YYYY")}`, color: "textDark" },
        ...(categories
          ? categories.map(category => ({
              text: category,
              color: "brandBrown",
            }))
          : []),
      ]
    },
    isLink: () => true,
    slug: ({ date, slug, permalink, archive = false }) => {
      if (archive && permalink) {
        return permalink
      }

      const dateObj = new moment(date)
      return generateBlogSlug(
        permalink,
        dateObj.format("YYYY"),
        dateObj.format("MM")
      )
    },
  },
  [ARCHIVE_TYPES.AWARDS]: {
    variant: () => "text",
    headline: ["headline"],
    subtitle: ["subtitle"],
    description: ["description"],
    meta: value => [
      {
        text: value.month ? `${value.month}, ${value.year}` : value.year,
        color: "brandBrown",
      },
    ],
  },
  [ARCHIVE_TYPES.CAREER]: {
    variant: () => "text",
    headline: ["headline"],
    description: ["description"],
    subtitle: value => (
      <Meta
        meta={[
          {
            text: value.location,
            color: "brandBrown",
          },
        ]}
      />
    ),
    children: value => (
      <Flex justifyContent="space-between">
        <Link
          to={`/career/${value.slug}`}
          state={{
            isModal: true,
          }}
        >
          <Button variant="text" colorScheme="brandBrown">
            Apply
          </Button>
        </Link>
        {value.details ? (
          <LinkWrapper
            variant="text"
            colorScheme="brandBrown"
            isDownload={true}
            to={value.details}
            label="View Details"
            external={true}
          />
        ) : null}
      </Flex>
    ),
  },
  [ARCHIVE_TYPES.AUTHOR]: {
    variant: () => "standard",
    headline: ["headline"],
    author: ["author"],
    image: ({ archive, image, description, headline }) => {
      if (archive) {
        return getArchiveImage(image, headline, description)
      }
      const fluid = getFluidGatsbyImage(
        { source: image, width: 800, height: 600 },
        { maxWidth: 600, quality: 90, isContentful: true }
      )
      return (
        <GatsbyImage
          alt={description || headline || null}
          fluid={fluid}
          objectFit="cover"
          style={{ height: "100%", width: "100%" }}
        />
      )
    },
    imageSx: ({ isFilterLeft }) => ({
      height: [
        "195px",
        "265px",
        "265px",
        "265px",
        isFilterLeft ? "195px" : "265px",
      ],
      width: "100%",
      objectFit: "cover",
    }),
    meta: ({ categories, date }) => {
      const dateObj = new moment(date)
      return [
        { text: `${dateObj.format("MMMM DD, YYYY")}`, color: "textDark" },
        ...(categories
          ? categories.map(category => ({
              text: category,
              color: "brandBrown",
            }))
          : []),
      ]
    },
    isLink: () => true,
    slug: ({ date, slug, permalink, archive = false }) => {
      if (archive && permalink) {
        return permalink
      }

      const dateObj = new moment(date)
      return generateBlogSlug(
        permalink,
        dateObj.format("YYYY"),
        dateObj.format("MM")
      )
    },
  },
}

export const FILTER_POSITIONS = {
  TOP: "Top",
  LEFT: "Left",
  NONE: "No Filter",
}

const ConnectedSearchBox = connectSearchBox(({ refine }) => (
  <Input
    type="input"
    placeholder="Search..."
    onChange={e => refine(e.target.value)}
    bg="baseWhite"
  />
))

const getAllMenuItems = ({
  isVertical,
  currentRefinement,
  refine,
  items,
  existingRefinements,
  attribute,
  type = null,
  ...rest
}) => {
  return (
    <>
      <FilterItem
        active={!currentRefinement}
        isVertical={isVertical}
        onClick={() => refine()}
        size="small"
      >
        All
      </FilterItem>
      {items.map(item => {
        if (existingRefinements.includes(item.value)) return null
        return (
          <FilterItem
            key={item.value}
            isVertical={isVertical}
            active={item.isRefined}
            size="small"
          >
            {attribute === ATTRIBUTE_NAMES.category &&
            type === ARCHIVE_TYPES.BLOG &&
            useAllSitePages_PageExists(`/blog/${createSlug(item.label)}/`) ? (
              <Link to={`/blog/${createSlug(item.label)}/`}>{item.label}</Link>
            ) : (
              <div onClick={() => refine(item.value)}>{item.label}</div>
            )}
          </FilterItem>
        )
      })}
    </>
  )
}

const getBlogMenuItems = ({
  isVertical,
  currentRefinement,
  refine,
  updateArticleType,
}) => {
  return (
    <>
      <FilterItem
        active={currentRefinement === ARTICLE_TYPES.BLOG}
        isVertical={isVertical}
        onClick={() => {
          refine(ARTICLE_TYPES.BLOG)
          updateArticleType(ARTICLE_TYPES.BLOG)
        }}
        size="small"
      >
        Blog Posts
      </FilterItem>
      <FilterItem
        active={currentRefinement === ARTICLE_TYPES.BUSH_STORY}
        isVertical={isVertical}
        onClick={() => {
          refine(ARTICLE_TYPES.BUSH_STORY)
          updateArticleType(ARTICLE_TYPES.BUSH_STORY)
        }}
        size="small"
      >
        Bush Stories
      </FilterItem>
    </>
  )
}

const getWLRMenuItems = ({
  isVertical,
  currentRefinement,
  refine,
  updateArticleType,
}) => {
  return (
    <>
      <FilterItem
        active={currentRefinement === ARTICLE_TYPES.WILDLIFE}
        isVertical={isVertical}
        onClick={() => {
          refine(ARTICLE_TYPES.WILDLIFE)
          updateArticleType(ARTICLE_TYPES.WILDLIFE)
        }}
        size="small"
      >
        Wildlife Reports
      </FilterItem>
      <FilterItem
        active={currentRefinement === ARTICLE_TYPES.BUSH_STORY}
        isVertical={isVertical}
        onClick={() => {
          refine(ARTICLE_TYPES.BUSH_STORY)
          updateArticleType(ARTICLE_TYPES.BUSH_STORY)
        }}
        size="small"
      >
        Bush Stories
      </FilterItem>
    </>
  )
}

const ConnectedMenu = connectMenu(props => {
  const { isVertical, attribute, isWLR } = props
  return (
    <>
      <Media greaterThanOrEqual="lg">
        <Filter isVertical={isVertical}>
          {isWLR
            ? getWLRMenuItems(props)
            : attribute === "articleType"
            ? getBlogMenuItems(props)
            : getAllMenuItems(props)}
        </Filter>
      </Media>
      <Media lessThan="lg">
        <Filter isVertical={false} hasScrollOverflow={true}>
          {isWLR
            ? getWLRMenuItems({ ...props, isVertical: false })
            : attribute === "articleType"
            ? getBlogMenuItems({ ...props, isVertical: false })
            : getAllMenuItems({ ...props, isVertical: false })}
        </Filter>
      </Media>
    </>
  )
})

const Hit = ({ type, ...props }) => {
  const cardProps = mapperWithFunction(props, CARD_PROPS_MAP[type])
  let slug = cardProps.slug
  if (slug && !slug.endsWith("/")) slug += "/"

  if (props.archive) {
    return (
      <a href={slug}>
        <Card {...cardProps} />
      </a>
    )
  }
  return (
    <>
      <Link to={slug}>
        <Card {...cardProps} />
      </Link>
    </>
  )
}

const ConnectedHits = connectHits(
  ({ hits, view, isFilterLeft, type, ...rest }) => {
    return (
      <GridContainer
        sx={{
          gridTemplateColumns: [
            "1fr",
            "1fr",
            "repeat(2, 1fr)",
            "repeat(3, 1fr)",
          ],
          gap: [4],
        }}
        width="100%"
      >
        {hits.map((hit, index) => (
          <GridItem key={hit.objectID} index={index} columns={[1, 1, 2, 3]}>
            <Hit {...hit} isFilterLeft={isFilterLeft} type={type} />
          </GridItem>
        ))}
      </GridContainer>
    )
  }
)

const ConnectedPagination = connectPagination(
  ({ nbPages, currentRefinement, refine, ...props }) => {
    const parentRef = useRef(null)
    const componentRef = useRef(null)

    useEffect(() => {
      if (componentRef.current) {
        const sectionParent = componentRef.current.closest("section")
        if (sectionParent) {
          parentRef.current = sectionParent.querySelector(":first-child")
        }
      }
    }, [])

    const currentPage = useRef(currentRefinement)
    const currentTotalPages = useRef(nbPages)

    useEffect(() => {
      if (
        currentPage.current === currentRefinement &&
        currentTotalPages.current === nbPages
      ) {
        return
      }
      currentPage.current = currentRefinement
      currentTotalPages.current = nbPages
      if (parentRef.current) {
        window.scrollTo({
          top: parentRef.current.offsetTop,
          behavior: "smooth",
        })
      }
    }, [currentRefinement, nbPages])
    return (
      <Box ref={componentRef}>
        <Pagination
          currentPage={currentRefinement}
          numberOfPages={nbPages}
          goToPage={pageNumber => refine(pageNumber)}
          {...props}
        />
      </Box>
    )
  }
)

function buildRefinements(refinements) {
  return (refinements || []).map(({ __typename, headline }, idx) => {
    const filterName = REFINEMENT_NAME_MAP[__typename]
    const isLast = idx === refinements.length - 1
    return `${filterName}:'${headline}'${!isLast ? " AND " : ""}`
  })
}

const HierarchicalMenu = ({ items, refine, createURL }) => (
  <Box mb={[3]}>
    <Filter isVertical={true}>
      {items.map(item => (
        <>
          <FilterItem
            href={createURL(item.value)}
            active={item.isRefined}
            isVertical={true}
            size="small"
            onClick={event => {
              event.preventDefault()
              refine(item.value)
            }}
          >
            {item.label} ({item.count})
          </FilterItem>
          <Box ml={[2]}>
            {item.items && (
              <HierarchicalMenu
                items={item.items.reverse()}
                refine={refine}
                createURL={createURL}
              />
            )}
          </Box>
        </>
      ))}
    </Filter>
  </Box>
)

const CustomHierarchicalMenu = connectHierarchicalMenu(HierarchicalMenu)

const monthNames = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
]

const transformMenuItems = (items, attribute) => {
  if (attribute === "articleType") {
    return orderBy(items, ["label"])
  } else if (attribute === "year") {
    return attribute === "year" ? orderBy(items, ["label"], ["desc"]) : items
  } else if (attribute === "year_hier.lvl0") {
    return items.sort((a, b) => {
      const aIdx = monthNames.findIndex(month => month === a.label)
      const bIdx = monthNames.findIndex(month => month === b.label)

      return aIdx > bIdx ? 1 : -1
    })
  }
  return items
}

const getDefaultRefinements = (type, attribute, defaultRefinement = null) => {
  switch (type) {
    case ARCHIVE_TYPES.BLOG:
      if (attribute === "articleType") {
        return ARTICLE_TYPES.BLOG
      } else if (attribute === "category") {
        return defaultRefinement ? defaultRefinement.headline : null
      }
      break
    case ARCHIVE_TYPES.WILDLIFE:
      if (attribute === "articleType") {
        return ARTICLE_TYPES.WILDLIFE
      }
      break
    default:
      break
  }

  return "" // Return an empty string if no default refinement is found for the given type and attribute.
}

const ArchiveWrapper = ({
  type,
  filterPosition,
  isSearchable,
  headline,
  headlineLineTwo,
  description,
  defaultRefinement,
  refinements,
}) => {
  const location = useLocation()
  const [searchState, setSearchState] = useState(urlToSearchState(location))
  const debouncedSetStateRef = useRef(null)

  function onSearchStateChange(updatedSearchState) {
    clearTimeout(debouncedSetStateRef.current)

    debouncedSetStateRef.current = setTimeout(() => {
      navigate(searchStateToUrl(updatedSearchState), {
        state: { keepScroll: true },
      })
    }, DEBOUNCE_TIME)

    setSearchState(updatedSearchState)
  }

  useEffect(() => {
    setSearchState(urlToSearchState(location))
  }, [location])

  const searchClient = algoliasearch(
    process.env.GATSBY_ALGOLIA_APP_ID,
    process.env.GATSBY_ALGOLIA_API_KEY
  )

  const indexName = ARCHIVE_INDEX_MAP[type]
  // Deprecate when everything has moved to new indices
  const postType = OLD_ARCHIVE_POST_TYPE_MAP[type]
  const articleType = NEW_ARCHIVE_POST_TYPE_MAP[type]
  const filters = ARCHIVE_FILTER_ATTRIBUTE_MAP[type]
  const isFilterLeft = filterPosition === FILTER_POSITIONS.LEFT
  const refinementsList = buildRefinements(refinements)
  const refinementValues = (refinements || []).map(({ headline }) => headline)

  let filterString
  if (postType) {
    if (postType === "Wildlife Report") {
      filterString = `articleType:'${articleType}' OR articleType:'Bush Stories'${
        refinementsList.length > 0 ? ` AND ${refinementsList}` : ""
      }`
    } else filterString = `articleType:'${postType}'`
  } else if (articleType) {
    filterString = `articleType:'${articleType}' OR articleType:'Bush Stories'${
      refinementsList.length > 0 ? ` AND ${refinementsList}` : ""
    }`
  } else if (type === ARCHIVE_TYPES.CAREER) {
    filterString = "isActive:true"
  } else if (type === ARCHIVE_TYPES.AUTHOR) {
    filterString = `author:'${defaultRefinement}'`
  } else {
    filterString = ""
  }

  const [currentArticleType, setArticleType] = React.useState(articleType)
  return (
    <Box as="section">
      <Container>
        <SectionHeading
          headline={headline}
          headlineLineTwo={headlineLineTwo}
          description={description ? description.description : null}
          // direction={"row"}
          alignment={"left"}
        />
        <InstantSearch
          searchClient={searchClient}
          indexName={indexName}
          searchState={searchState}
          onSearchStateChange={onSearchStateChange}
          createURL={createURL}
        >
          <Configure hitsPerPage={9} filters={filterString} />
          <Flex
            mt={[5]}
            flexDirection={[
              "column",
              "column",
              "column",
              filterPosition === FILTER_POSITIONS.TOP ? "column" : "row",
            ]}
            alignItems="flex-start"
          >
            <Flex
              justifyContent={isFilterLeft ? "flex-start" : "space-between"}
              flexDirection={[
                "column",
                "column",
                isFilterLeft ? "column" : "row",
              ]}
              flexShrink="0"
              mr={isFilterLeft ? [0, 0, 0, 8] : [0]}
              mb={isFilterLeft ? [0] : [3]}
              sx={{
                position: [
                  "static",
                  "static",
                  "static",
                  isFilterLeft ? "sticky" : "static",
                ],
                top: "85px",
                left: 0,
              }}
              width={["100%", "100%", "auto"]}
            >
              {isSearchable && (
                <Box
                  mb={isFilterLeft ? [4] : [0]}
                  order={[0, 0, isFilterLeft ? 0 : 1]}
                  width={isFilterLeft ? "auto" : "300px"}
                >
                  {isFilterLeft && (
                    <Box mb={[1]}>
                      <Heading size="h6" fontWeight="bold">
                        Search
                      </Heading>
                    </Box>
                  )}
                  <ConnectedSearchBox />
                </Box>
              )}
              {filterPosition !== FILTER_POSITIONS.NONE && (
                <Box order={[1, 1, isFilterLeft ? 1 : 0]}>
                  {type === ARCHIVE_TYPES.BLOG
                    ? [
                        "articleType",
                        ...([ARTICLE_TYPES.BLOG, ""].includes(
                          currentArticleType
                        )
                          ? ["category"]
                          : ["region"]),
                      ].map(attribute => (
                        <Box key={attribute} mb={[5]}>
                          <Box mb={[3]}>
                            <Heading size="h6" fontWeight="bold">
                              Select {ATTRIBUTE_NAMES[attribute]}
                            </Heading>
                          </Box>
                          <Box pl={isFilterLeft ? [1] : [0]}>
                            <ConnectedMenu
                              limit={14}
                              existingRefinements={refinementValues}
                              defaultRefinement={getDefaultRefinements(
                                type,
                                attribute,
                                defaultRefinement
                              )}
                              isVertical={isFilterLeft}
                              attribute={attribute}
                              transformItems={items =>
                                transformMenuItems(items, attribute)
                              }
                              updateArticleType={articleType =>
                                setArticleType(articleType)
                              }
                              type={ARCHIVE_TYPES.BLOG}
                            />
                          </Box>
                        </Box>
                      ))
                    : type === ARCHIVE_TYPES.WILDLIFE
                    ? ["articleType", "region", "year_hier.lvl0"].map(
                        attribute => (
                          <Box key={attribute} mb={[5]}>
                            <Box mb={[3]}>
                              <Heading size="h6" fontWeight="bold">
                                Select {ATTRIBUTE_NAMES[attribute]}
                              </Heading>
                            </Box>
                            <Box pl={isFilterLeft ? [1] : [0]}>
                              <ConnectedMenu
                                limit={attribute === "year_hier.lvl0" ? 12 : 10}
                                existingRefinements={refinementValues}
                                defaultRefinement={getDefaultRefinements(
                                  type,
                                  attribute,
                                  defaultRefinement
                                )}
                                isVertical={isFilterLeft}
                                attribute={attribute}
                                transformItems={items =>
                                  transformMenuItems(items, attribute)
                                }
                                updateArticleType={articleType =>
                                  setArticleType(articleType)
                                }
                                isWLR={attribute === "articleType"}
                              />
                            </Box>
                          </Box>
                        )
                      )
                    : filters.map((attribute, index) => {
                        const hasMultipleFilters = filters.length > 1
                        return (
                          <Box key={attribute} mb={[5]}>
                            {hasMultipleFilters && (
                              <Box mb={[3]}>
                                <Heading size="h6" fontWeight="bold">
                                  Select {ATTRIBUTE_NAMES[attribute]}
                                </Heading>
                              </Box>
                            )}
                            <Box
                              pl={
                                hasMultipleFilters && isFilterLeft ? [1] : [0]
                              }
                            >
                              <ConnectedMenu
                                limit={attribute === "year_hier.lvl0" ? 12 : 10}
                                existingRefinements={refinementValues}
                                defaultRefinement={getDefaultRefinements(
                                  type,
                                  attribute,
                                  defaultRefinement
                                )}
                                isVertical={isFilterLeft}
                                attribute={attribute}
                                transformItems={items =>
                                  transformMenuItems(items, attribute)
                                }
                                updateArticleType={articleType =>
                                  setArticleType(articleType)
                                }
                              />
                            </Box>
                          </Box>
                        )
                      })}
                </Box>
              )}
            </Flex>
            <Flex flexDirection="column" alignItems="center" width="100%">
              <ConnectedHits
                type={type}
                hitComponent={Hit}
                isFilterLeft={isFilterLeft}
              />
              <Box width={[1, 1, "auto", "auto"]} py={[3]}>
                <ConnectedPagination
                  to={location.pathname}
                  LinkComponent={Link}
                />
              </Box>
            </Flex>
          </Flex>
        </InstantSearch>
      </Container>
    </Box>
  )
}

export default ArchiveWrapper

export const archiveFields = graphql`
  fragment ArchiveFields on ContentfulLayoutArchive {
    type
    filterPosition
    isSearchable
    headline
    headlineLineTwo
    description {
      description
    }
    defaultRefinement {
      headline
    }
  }
`
